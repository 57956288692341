import React from "react";

export default function aboutlistitem({ image, title, desc }) {
  return (
    <li>
      <div className="aboutlistimage">
        <img src={image} alt="About Us" />
      </div>
      <div className="aboutlistitem">
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </li>
  );
}
