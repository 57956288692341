import React, { useState } from "react";
import "./styles/contact.css";
import emailjs from "@emailjs/browser";
import phonecontact from "../images/icons/phonecontact.png";
import emailcontact from "../images/icons/emailcontact.png";
import locationcontact from "../images/icons/locationcontact.png";
import clockcontact from "../images/icons/clockcontact.png";
import linkedin from "../images/icons/linkedincontact.svg";
import twitter from "../images/icons/twittercontact.svg";

const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!phonecheck(phone)) return;

    const serviceId = "service_rlt9p98";
    const templateId = "template_ibvexkj";
    const publicKey = "dfrCbnHTY24UALx3W";
    const templateParams = {
      from_name: name,
      from_phone: phone,
      from_email: email,
      from_subject: subject,
      from_message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        setName("");
        setPhone("");
        setEmail("");
        setSubject("");
        setMessage("");
        console.log("Email sent successfully", response.status, response.text);
      })
      .catch((error) => {
        console.log("Email sending failed", error);
      });
  };

  const phonecheck = (phoneNumber) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      alert("Please enter a valid phone number");
      return false;
    }
    return true;
  };

  return (
    <div className="contactmain">
      <div className="contactmainbody">
        <div className="contactleft">
          <h1>Get In Touch With Us</h1>
          <p>
            We would be happy to meet you and learn all about your business. Contact us Today!
          </p>
          <h3>Opening Hours</h3>
          <p>Mon - Friday 10.00 - 18.00</p>
          <div className="contactsocials">
            <ul>
              <li>
                <img src={twitter} alt="Twitter" />
              </li>
              <li>
                <img src={linkedin} alt="LinkedIn" />
              </li>
            </ul>
          </div>
          <div className="contactinfo">
            <div className="contactelement">
              <div className="leftcontactelement">
                <img src={phonecontact} alt="phone" />
              </div>
              <div className="rightcontactelement">
                <h3>Contact Us</h3>
                <p>+91 8431219967</p>
              </div>
            </div>
            <div className="contactelement">
              <div className="leftcontactelement">
                <img src={locationcontact} alt="location" />
              </div>
              <div className="rightcontactelement">
                <h3>Address</h3>
                <p>Whitefield, Bangalore</p>
              </div>
            </div>
            <div className="contactelement">
              <div className="leftcontactelement">
                <img src={emailcontact} alt="email" />
              </div>
              <div className="rightcontactelement">
                <h3>Email Us</h3>
                <p>help.finark@gmail.com</p>
              </div>
            </div>
            <div className="contactelement">
              <div className="leftcontactelement">
                <img src={clockcontact} alt="Timing" />
              </div>
              <div className="rightcontactelement">
                <h3>Our Timing</h3>
                <p>
                  Mon - Friday <br />
                  10.00 - 18.00
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="contactright">
          <h1>Make an Appointment</h1>
          <p>
            Get in touch with us to discuss how we can elevate your business.
          </p>
          <form onSubmit={handleSubmit}>
            <input
              className="halfinput"
              type="text"
              placeholder="Name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="halfinput"
              type="number"
              placeholder="Phone"
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              className="halfinput"
              type="email"
              placeholder="Email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="halfinput"
              type="text"
              placeholder="Subject"
              value={subject}
              required
              onChange={(e) => setSubject(e.target.value)}
            />
            <textarea
              placeholder="Message"
              value={message}
              required
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
