import React from "react";

export default function costeffect({ image }) {
  return (
    <div className="aboutbottomcard">
      <div className="aboutleft">
        <img src={image} alt="Logo" />
      </div>
      <div className="aboutmoreright">
        <h3>Budget Friendly</h3>
        <p>Get high-quality, customized solutions without breaking the bank.</p>
      </div>
    </div>
  );
}
