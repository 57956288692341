import React from "react";
import logo from "../images/logo192.png";
import cost from "../images/cost.png";
import boost from "../images/boost.png";
import grow from "../images/grow.png";
import award from "../images/award.png";
import world from "../images/world.png";
import "./styles/about.css";
import GrowBusiness from "./elements/growbusiness";
import CostEffect from "./elements/costeffect";
import Boost from "./elements/boost";
import aboutUs from "../images/aboutusleft.jpg";
import aboutleftimage from "../images/contactformbg.png";
import companyLeft from "../images/company.jpg";
import AboutListItem from "./elements/aboutlistitem";
import aboutusvector from "../images/icons/aboutuvsector.png";
export default function about() {
  return (
    <div className="aboutmain">
      <div className="abouttop">
        <div className="abouttopleft">
          <img src={companyLeft} alt="About Us" />
        </div>
        <div className="abouttopright">
          <div className="aboutusheader">
            <img src={aboutusvector} alt="About Us" />
            <h2>ABOUT US</h2>
          </div>
          <h1>We're your Trusted Partner for Software and IT Solutions.</h1>
          <p>
          At Finark Technologies, we specialize in delivering comprehensive software and IT consulting services tailored to elevate your business. From cutting-edge mobile app development to innovative web solutions.
          </p>
          <ul>
            <AboutListItem
              image={award}
              title="Award Winning Expertise"
              desc="Our commitment to excellence and innovation has earned us recognition as a leader in providing top-notch software solutions."
            />
            <AboutListItem
              image={world}
              title="World-Class Services"
              desc="Our team of experts is dedicated to delivering customized solutions that drive growth and enhance brand recognition."
            />
          </ul>
        </div>
      </div>
      <div className="aboutbottom">
        <GrowBusiness image={grow} />
        <CostEffect image={cost} />
        <Boost image={boost} />
      </div>
    </div>
  );
}
