import React from "react";
import "./styles/footer.css";
import logo from "../images/logo192.png";
import phone from "../images/icons/phonefooter.png";
import email from "../images/icons/emailfooter.png";
import twitter from "../images/icons/twitter.svg";
import linkedin from "../images/icons/linkedin.svg";
import aboutfooter from "../images/icons/aboutusfooter.png";
import projectfooter from "../images/icons/projectfooter.png";
import servicefooter from "../images/icons/servicesfooter.png";
import testimonialfooter from "../images/icons/testimonialsfooter.png";
export default function Footer() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="footerwork">
        <div className="footerworkleft">
          <div className="footerworklefthead">
            <h1>About Company</h1>
            <div className="footerworkleftbody">
              <p>
                We specialize in delivering innovative software solutions, from web and mobile app development to comprehensive IT consulting.
              </p>
              <a
                href="https://calendly.com/help-finark/finark-software-development-consultation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Get In Touch</button>
              </a>
            </div>
          </div>
        </div>
        <div className="footerworkright">
          <div className="footerworkrightbody">
            <div className="footerworkrightheader">Quick Links</div>

            <ul>
              <li onClick={() => scrollToSection("about")}>
                <img src={aboutfooter} alt="Footer Icons" />
                About Us
              </li>
              <li onClick={() => scrollToSection("project")}>
                <img src={projectfooter} alt="Footer Icons" />
                Projects
              </li>
              <li onClick={() => scrollToSection("service")}>
                <img src={servicefooter} alt="Footer Icons" />
                Our Services
              </li>
              <li onClick={() => scrollToSection("testimonial")}>
                <img src={testimonialfooter} alt="Footer Icons" />
                Testimonials
              </li>
            </ul>
          </div>
          <div className="footerworkrightbody">
            <div className="footerworkrightheader">Need Help</div>

            <ul>
              <li>
                <img src={phone} alt="phone" />
                +91 8431219967
              </li>
              <li>
                <img src={email} alt="mail" id="mailicon" />
                help.finark@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footertop">
          <img src={logo} alt="Logo" />
          <h1>Finark</h1>
        </div>
        <div className="footerbottom">
          <div className="footerbottomright">
            <img src={twitter} alt="Twitter" />
            <img src={linkedin} alt="LinkedIn" />
          </div>
          <div className="footerbottomlefttext">
            <p>Privacy Policy</p>
            <p>Terms and Conditions</p>
          </div>
          <div className="footerbottomleft">
            <p>© 2024 Finark. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}
