import React from "react";

export default function boost({ image }) {
  return (
    <div className="aboutbottomcard">
      <div className="aboutleft">
        <img src={image} alt="Logo" />
      </div>
      <div className="aboutmoreright">
        <h3>Boost Performance</h3>
        <p>Enhance efficiency and productivity with our cutting-edge technology</p>
      </div>
    </div>
  );
}
