import "./App.css";
import Home from "./ components/home";
import About from "./ components/about";
import Project from "./ components/project";
import Services from "./ components/services";
import Testimonial from "./ components/testimonial";
import Contact from "./ components/contact";
import Footer from "./ components/footer";
function App() {
  return (
    <>
      <div id="home">
        <Home />
      </div>
      <div id="about" className="about">
        <About />
      </div>
      <div id="project">
        <Project />
      </div>
      <div id="service">
        <Services />
      </div>
      <div id="testimonial">
        <Testimonial />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </>
  );
}

export default App;
