import React, { useRef, useState, useEffect } from "react";
import arrowright from "../images/icons/arrowright.png";
import arrowleft from "../images/icons/arrowleft.png";
import "./styles/testimonial.css";
import TestimonialCard from "./elements/testimonialcard";

export default function Testimonial() {
  const cardWrapperRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonialsPerView, setTestimonialsPerView] = useState(3);
  const totalTestimonials = 6;

  const updateTestimonialsPerView = () => {
    if (window.innerWidth <= 1000) {
      setTestimonialsPerView(1);
    } else {
      setTestimonialsPerView(3);
    }
  };

  useEffect(() => {
    updateTestimonialsPerView();
    window.addEventListener("resize", updateTestimonialsPerView);
    return () => {
      window.removeEventListener("resize", updateTestimonialsPerView);
    };
  }, []);

  useEffect(() => {
    const maxIndex = Math.max(0, totalTestimonials - testimonialsPerView);
    if (currentIndex > maxIndex) {
      setCurrentIndex(maxIndex);
    } else if (currentIndex < 0) {
      setCurrentIndex(0);
    }
  }, [testimonialsPerView, currentIndex, totalTestimonials]);

  const arrowPrev = () => {
    const cardWrapper = cardWrapperRef.current;
    const widthToScroll =
      cardWrapper.children[0].offsetWidth * testimonialsPerView;
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - testimonialsPerView);
      cardWrapper.scrollLeft -= widthToScroll;
    }
  };

  const arrowNext = () => {
    const cardWrapper = cardWrapperRef.current;
    const widthToScroll =
      cardWrapper.children[0].offsetWidth * testimonialsPerView;
    if (currentIndex < totalTestimonials - testimonialsPerView) {
      setCurrentIndex((prevIndex) => prevIndex + testimonialsPerView);
      cardWrapper.scrollLeft += widthToScroll;
    }
  };

  const totalScrolls = Math.ceil(totalTestimonials / testimonialsPerView);
  const currentScroll = Math.ceil((currentIndex + 1) / testimonialsPerView);

  return (
    <div className="testiominalsmain">
      <div className="testimonialheader">
        <h1>What Our Users Say</h1>
        <div className="bottomarrows">
          <button className="arrow prev" onClick={arrowPrev}>
            <img src={arrowleft} alt="Previous" />
          </button>
          <p>{`${currentScroll}/${totalScrolls}`}</p>
          <button className="arrow next" onClick={arrowNext}>
            <img src={arrowright} alt="Next" />
          </button>
        </div>
      </div>

      <div className="wrapper">
        <div className="card-wrapper" ref={cardWrapperRef}>
          <TestimonialCard
            name="Prabhakar Ramesh"
            content="Finark was the perfect choice for our company’s mobile application development needs. They were able to develop both the iOS and Android versions of our app with a seamless user experience. Their team was responsive and professional throughout the project."
          />
          <TestimonialCard
            name="Ritesh Chourasia"
            content=" I had the pleasure of working with Finark for my company’s web app development needs. Their Web development services were excellent, and they went above and beyond in creating a mobile view with excellent UX and UI design. I highly recommend them."
          />
          <TestimonialCard
            name="Mona Bhardwaj"
            content="I approached Finark with a complex idea for a multi-platform app, and they delivered beyond my expectations. They kept me in the loop throughout the process, making it a smooth and stress-free experience."
          />
          <TestimonialCard
            name="Anita Verma"
            content="Finark transformed our outdated website into a modern, responsive platform that has significantly improved our customer engagement. Their team was patient and attentive to our needs, ensuring every detail was perfect."
          />
          <TestimonialCard
            name="Rahul Desai"
            content="Finark’s web development team brought our e-commerce site to life with stunning visuals and seamless functionality. They were quick to implement feedback and ensured our site was optimized for both desktop and mobile."
          />
          
        </div>
      </div>
    </div>
  );
}
