import React, { useState, useEffect } from "react";
import logo from "../images/logo192.png";
import homeright from "../images/homeright.svg";
import griplines from "../images/icons/griplines.png";
import "./styles/home.css";

export default function Home() {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1000) {
        setDropdownVisible(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDropdownMenu = () => {
    if (window.innerWidth <= 1000) {
      setDropdownVisible(!dropdownVisible);
    }
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    setDropdownVisible(false);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="navbar">
        <div className="navleft">
          <img src={logo} alt="Logo" />
        </div>
        <div className="navright">
          <ul>
            <li onClick={() => scrollToSection("about")}>About Us</li>
            <li onClick={() => scrollToSection("project")}>Projects</li>
            <li onClick={() => scrollToSection("service")}>Our Services</li>
            <li onClick={() => scrollToSection("testimonial")}>Testimonials</li>
            <li>
              <a
                href="https://calendly.com/help-finark/finark-software-development-consultation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Connect With Us</button>
              </a>
            </li>
          </ul>
        </div>
        <div
          className="navrightmobile"
          id="mobileMenuIcon"
          onClick={toggleDropdownMenu}
        >
          <img src={griplines} alt="Gripline" />
        </div>
      </div>
      {dropdownVisible && (
        <div className="dropdown-menu" id="dropdownMenu">
          <ul>
            <li onClick={() => scrollToSection("about")}>About Us</li>
            <li onClick={() => scrollToSection("project")}>Projects</li>
            <li onClick={() => scrollToSection("service")}>Our Services</li>
            <li onClick={() => scrollToSection("testimonial")}>Testimonials</li>
            <li>
              <a
                href="https://calendly.com/help-finark/finark-software-development-consultation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Connect With Us</button>
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="homemain">
        <div className="homeleft">
          <h1>Innovating Your Vision into Digital Reality.</h1>
          <h3>
            Empowering Businesses with Tailored Software & Tech Solutions
          </h3>
          <button onClick={() => scrollToSection("about")}>
            Know More About Us
          </button>
        </div>
        <div className="homeright">
          <img src={homeright} alt="Home Right" />
        </div>
      </div>
    </div>
  );
}
