import React, { useState } from "react";
import "./styles/services.css";
import ServiceRight from "./elements/serviceright";
import image1 from "../images/software.svg";
import image2 from "../images/web.svg";
import image3 from "../images/mobile.svg";
import image4 from "../images/game.svg";
import image5 from "../images/design.svg";
import image6 from "../images/figma.svg";

export default function Services() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [selectedService, setSelectedService] = useState(
    "Software Development"
  );

  const services = [
    {
      name: "Software Development",
      image: image1,
      heading: "Software Development",
      content:
        "We develop software for all platforms. We have a team of developers who are experienced in developing software for all platforms.",
      point1: "Cross Platform Development",
      point2: "Native Development",
    },
    {
      name: "Ecommerce Development",
      image: image2,
      heading: "Ecommerce Development",
      content:
        "We build robust ecommerce platforms tailored to your business needs.",
      point1: "Custom Solutions",
      point2: "Third-Party Integrations",
    },
    {
      name: "App Development",
      image: image3,
      heading: "App Development",
      content:
        "We create mobile applications for both Android and iOS platforms.",
      point1: "iOS Development",
      point2: "Android Development",
    },
    {
      name: "Game Development",
      image: image4,
      heading: "Game Development",
      content: "Our team designs and develops engaging and interactive games.",
      point1: "2D and 3D Games",
      point2: "Cross-Platform Support",
    },
    {
      name: "Graphic Design",
      image: image5,
      heading: "Graphic Design",
      content:
        "We provide creative graphic design solutions to make your brand stand out.",
      point1: "Brand Identity",
      point2: "Marketing Materials",
    },
    {
      name: "Figma Design",
      image: image6,
      heading: "Figma Design",
      content:
        "We offer Figma design services to create user-friendly interfaces.",
      point1: "Prototyping",
      point2: "Collaborative Design",
    },
  ];

  const selectedServiceDetails = services.find(
    (service) => service.name === selectedService
  );

  return (
    <div className="servicesmain">
      <h1>Our Services</h1>
      <div className="servicesbody">
        <div className="servicesleft">
          <ul>
            {services.map((service) => (
              <li
                key={service.name}
                onClick={() => {
                  setSelectedService(service.name);
                  scrollToSection("service");
                }}
                className={selectedService === service.name ? "active" : ""}
              >
                {service.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="servicesright">
          <ServiceRight
            image={selectedServiceDetails.image}
            heading={selectedServiceDetails.heading}
            content={selectedServiceDetails.content}
            point1={selectedServiceDetails.point1}
            point2={selectedServiceDetails.point2}
          />
        </div>
      </div>
    </div>
  );
}
