import React from "react";
import point from "../../images/icons/target.png";
export default function serviceright({
  image,
  heading,
  content,
  point1,
  point2,
}) {
  return (
    <div className="servicerightmain">
      <div className="serviceleft">
        <img src={image} alt="About Service" />
      </div>
      <div className="serviceright">
        <h3>{heading}</h3>
        <p>{content}</p>
        <ul>
          <li>
            <img src={point} alt="Point" />
            <p>{point1}</p>
          </li>
          <li>
            <img src={point} alt="Point" />
            <p>{point2}</p>
          </li>
        </ul>
      </div>
    </div>
  );
}
