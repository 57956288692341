import React from "react";

export default function growbusiness({ image }) {
  return (
    <div className="aboutbottomcard">
      <div className="aboutleft">
        <img src={image} alt="Logo" />
      </div>
      <div className="aboutmoreright">
        <h3>Grow Your Business</h3>
        <p>Unlock new opportunities with our innovative software solutions.</p>
      </div>
    </div>
  );
}
