import React from "react";
import "../styles/project.css";
export default function projectcard({
  image,
  heading,
  body,
  about,
  apptype,
  linktype,
  imagebottom,
}) {
  return (
    <div className="projectcard">
      <div className="projectleft">
        <a href="/#">
          <img src={image} alt="Project" />
        </a>
      </div>
      <div className="projectright">
        {/* <img src={imagebottom} alt="Bottom" /> */}
        <h2>{apptype}</h2>
        <h1>{heading}</h1>
        <p className="projectcardbody">{body}</p>
        <a href={linktype} target="_blank">
          <button>Read More</button>
        </a>
      </div>
    </div>
  );
}
