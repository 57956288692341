import React from "react";
import ProjectCard from "./elements/projectcard";
// import logo from "../images/logo192.png";
import "../ components/styles/project.css";
import pfm from "../images/pfm.png";
import vahaanimage from "../images/vahaan.png";
export default function project() {
  const logo = "https://sdlccorp.com/wp-content/uploads/2023/09/Kayak.webp";
  const imagebottom =
    "https://sdlccorp.com/wp-content/uploads/2023/09/Kayak-logo-1-1.webp";
  const points = [
    "Flight Booking",
    "Rental Cars",
    "Hotel Booking",
    "Travel Planning",
    "Budgeting",
  ];
  return (
    <div className="projectmain">
      <h1>Portfolio</h1>
      <div className="projectrow">
        <ProjectCard
          heading="InFinance"
          image={pfm}
          imagebottom={imagebottom}
          about={points.map((point, index) => (
            <p key={index}>{point}</p>
          ))}
          body="Effortlessly manage your personal finances and subscriptions all in one place with our intuitive all-in-one platform."
          apptype="Mobile App"
        />
        <ProjectCard
          heading="Vahaan"
          image={vahaanimage}
          imagebottom={imagebottom}
          about={points.map((point, index) => (
            <p key={index}>{point}</p>
          ))}
          body="With Vaahan, you can buy/sell/rent high-quality, super bikes that are as good as new all-in-one platform."
          apptype="Mobile/Web App"
          linktype="https://finarkvahaan.web.app/"
        />
        <ProjectCard
          heading="OpenYarn"
          image={logo}
          imagebottom={imagebottom}
          about={points.map((point, index) => (
            <p key={index}>{point}</p>
          ))}
          body="Discover the latest in news, travel, and luxury with our vibrant blogging platform, where every story inspires a new adventure."
          apptype="Mobile/Web App"
          linktype="https://openyarn.com/"
        />
      </div>
    </div>
  );
}
